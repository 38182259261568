import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { withTheme } from 'styled-components';

import { reactiveTemplateConfigSelector } from 'common/selectors/theme';
import { getFavoritesProductsSelector } from 'common/selectors/page';

import { fireGTMEvent } from 'common/utils/gtm';
import { PRODUCT_VIEW } from 'common/constants/GTMConstants';

import loadable from '@loadable/component';
const FavouriteProductsVariant = loadable(props =>
  import(`common/blocks/products/favourite/${props.blockVariant}`)
);

const FavouriteProducts = ({
  t,
  settings = {},
  blockSettings,
  products,
  headline = null,
  theme,
  gtm = { list: 'Favourite' }
}) => {
  if (_.isEmpty(products)) {
    return null;
  }
  React.useEffect(() => {
    fireGTMEvent(PRODUCT_VIEW, products, _.get(gtm, 'list', 'Favourite'));
  }, []);
  return (
    <Fragment>
      {headline}
      <FavouriteProductsVariant
        t={t}
        blockVariant={_.get(blockSettings, 'blockVariant')}
        settings={settings}
        data={products}
        theme={theme}
        gtm={gtm}
      />
    </Fragment>
  );
};

const makeMapStateToProps = () => {
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'FavouriteProductsVariant'
  );
  const getProducts = getFavoritesProductsSelector();
  return (state, props) => {
    return {
      products: getProducts(state, props),
      blockSettings: {
        blockVariant: reactiveTemplateConfig(state, props)
      }
    };
  };
};

const FavouriteProductsConnected = connect(makeMapStateToProps)(
  FavouriteProducts
);

export default withTranslation()(withTheme(FavouriteProductsConnected));
